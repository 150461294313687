import { LitElement, html, css, TemplateResult } from 'lit';
import tabsCss from '../scss/tabs.scss';
import {customElement} from "lit/decorators.js";

function partMap(parts): string {
  return Object.entries(parts)
    .filter(([key, value]) => value)
    .map(([key, value]) => key).join(' ');
}

@customElement('t3-tabs')
export class T3Tabs extends LitElement {

  get tabs(): Element[] {
    const slot = this.shadowRoot.querySelector('slot');
    return slot ? slot.assignedElements(): [];
  }

  selectTab(selected) {
    for (let tab of this.tabs)
      tab.selected = tab == selected;
    this.requestUpdate();
  }

  firstUpdated() {
    this.tabs.find(tab => tab.selected) || this.selectTab(this.tabs[0])
  }

  constructor() {
    super();
  }

  static get styles() {
    return tabsCss;
  }

  connectedCallback(): void {
    super.connectedCallback();
  }

  /*createRenderRoot() { // this will disable slot style drawing
    return this;
  }*/

  // correcting the currently available fields for navbar data field.
  // part=${this.partMap({tab: true, 'tab-selected': tab.selected})}
  protected render(): TemplateResult {
    return html`
      <ul class="nav nav-tabs nav-fill">
        ${this.tabs.map(tab => html`
          <li class="nav-item" @click=${ev => this.selectTab(tab)}>
            <a class=${partMap({'nav-link': true, 'active': tab.selected})}>
              ${tab.title}
            </a>
          </li>
        `)}
      </ul>

      <slot part="content" @slotchange=${ev => this.requestUpdate()}></slot>
      `;
  }
}

@customElement('t3-tab')
class Tab extends LitElement {
  static get properties() {
    return {
      title: {type: String, reflect: true},
      selected: {type: Boolean, reflect: true}
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
  }

  static get styles() {
    return css`
      :host(:not([selected])) {
        display: none;
      }
    `
  }

  // class=${partMap({'fade': true, 'show': this.selected})}
  render() {
    return html `
      <slot></slot>  
    `;
  }
}
