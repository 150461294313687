import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import navBarCss from '../scss/navbar.scss';

@customElement('t3-navbar-item')
export class NavbarItem extends LitElement {
    constructor() {
        super();
    }

    static get properties() {
        return {
            name: {type: String},
            icon: {type: String},
            link: {type: String}
        };
    }

    static get styles() {
        return [navBarCss, css``];
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    // correcting the currently available fields for navbar data field.
    protected render(): TemplateResult {
        return html`
            <li class="nav-item">
                <a class="nav-link spf-link" href="${this.link}">
                    <i class="${this.icon} mr-1"></i>
                    ${this.name}
                </a>
            </li>
        `;
    }

    protected createRenderRoot(): Element | ShadowRoot {
        return this; // super.createRenderRoot();
    }
}
