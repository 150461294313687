import {LitElement, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('student-basic-card')
class StudentCard extends LitElement {

    @property({type: String}) campusID = '';
    @property({type: String}) netID = '';
    @property({type: String}) fullName = '';
    @property({type: String}) email = '';
    @property({type: String}) orcid = '';
    @property({type: String}) cvLink = '';
    @property({type: String}) avatarLink = '';
    @property({type: String}) currentDepartment = '';
    @property({type: String}) currentAcademicPlan = '';
    @property({type: String}) currentAcademicProgram = '';
    @property({type: String}) admitAcademicPlan = '';
    @property({type: String}) admitAcademicProgram = '';
    @property({type: String}) primaryAdvisor = '';
    @property({type: String}) coAdvisor = '';

    static get properties() {
        return {
            startTerm: { type: String }
        }
    }

    constructor() {
       super();
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    createRenderRoot() {
        return this;
    }

    render() {
        return html`
            <div class="card mt-3 mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-3 col-sm-2 col-md-2 col-lg-2">
                            <div class="avatar avatar-3xl mt-md-2 ml-n1 ml-md-2 ml-lg-2 ml-xl-3">
                                <img class="rounded-circle img-thumbnail shadow-sm" 
                                     src="${this.avatarLink}" width="600">
                            </div>

                        </div>
                        <div class="col-9 col-sm-5 col-md-4 col-lg-5 mb-2">
                            <h4 class="mb-1">
                                <span class="mr-1 h4">
                                    ${this.fullName}
                                </span>
                                <a href="mailto:${this.email}" title="Email">
                                    <i class="fas fa-envelope-open-text"></i>
                                </a>
                                <a href="${this.orcid}" title="ORCID">
                                    <i class="fab fa-orcid"></i>
                                </a>
                                <a href="${this.cvLink}" alt="Student CV">
                                    <i class="far fa-file-alt"></i>
                                </a>
                            </h4>

                            <h5 class="font-weight-bold mb-1">${this.currentDepartment}</h5>
                            <p class="text-500">${this.currentAcademicProgram} (${this.currentAcademicPlan})</p>
                        </div>

                        <div class="col mt-2">

                            <div class="media-body mb-2">
                                <span class="float-left font-weight-bolder">Unique ID</span>
                                <span class="float-right">${this.campusID}</span>
                                <span class="clearfix"></span>
                            </div>

                            <div class="media-body mb-2">
                                <span class="float-left font-weight-bolder">Advisor</span>
                                <span class="float-right"><a class="text-primary cursor-pointer" onclick="t3student.changeAdvisor('${this.netID}')">${(this.primaryAdvisor == "") ? 'Not Available': this.primaryAdvisor}</a></span>
                                <span class="clearfix"></span>
                            </div>

                            <div class="media-body mb-2">
                                <span class="float-left font-weight-bolder">Co-Advisor</span>
                                <span class="float-right"><a class="text-primary cursor-pointer" onclick="t3student.changeCoAdvisor('${this.netID}')">${(this.coAdvisor == "") ? 'Not Available': this.coAdvisor}</a></span>
                                <span class="clearfix"></span>
                            </div>

                            <div class="media-body mb-2">
                                <span class="float-left font-weight-bolder">Program Start</span>
                                <span class="float-right">${this.startTerm}</span>
                                <span class="clearfix"></span>
                            </div>

                            <div class="media-body mb-2">
                                <span class="float-left font-weight-bolder">Admit Program</span>
                                <span class="float-right">${this.admitAcademicProgram} (${this.admitAcademicPlan})</span>
                                <span class="clearfix"></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    `;
    }
}
