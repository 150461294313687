import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import frsGateSvg from '../../assets/icons/frs_gate.svg';

@customElement('t3-frs-badge')
export class FrsBadge extends LitElement {
  @property({type: Number}) level = 0;
  @property({type: String}) size = 'h4';

  connectedCallback() {
    super.connectedCallback();
  }

  createRenderRoot() {
    return this;
  }

  static get styles() {
    return css`.badge-mt {
      margin-top: 1.33rem !important;
    }`
  }

  protected render() {
    return this.level > 0
      ? html`<span class="h3 fa-layers fa-fw text-success" title="Mentor Training Level">
      <i class="fas fa-certificate"></i>
      <span class="h5 text-white fa-layers-text fa-inverse">${this.level}</span>
      </span>`
      : html``; //
  }
}

@customElement('t3-frs-badge-gateway')
export class FrsBadgeGateway extends LitElement {
  @property({type: Object}) years = [];
  @property({type: String}) yearsString = '';
  @property({type: String}) for = '';

  static get styles() {
    return css``
  }

  constructor() {
    super();
    this.for = 'simple-target';
  }

  createRenderRoot() {
    return this;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  protected render() {
    return this.yearsString == ""? html`` : html`
      <div id="${this.for}" class=""> <!-- icon svg-icon-layer //-->
        ${unsafeHTML(frsGateSvg)}
      </div>
      <t3-tooltip class="d-none" for="${this.for}">
        ${unsafeHTML(this.yearsString)}
      </t3-tooltip>
    `;
  }
}

