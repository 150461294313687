import {css, CSSResultArray, html, LitElement, TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import buttonStyle from '../t3-button/style.scss';

type RenderAnchorOptions = {
  id: string;
  className?: string;
  ariaHidden?: boolean;
  anchorContent?: TemplateResult | TemplateResult[];
  labelledby?: string;
  tabindex?: -1 | 0;
};

@customElement('t3-button')
export class Button extends LitElement {
  public static get styles(): CSSResultArray {
    return [buttonStyle, css`::slotted(*) {
  font-weight: bold !important;
}`]; // ...super.styles,
  }

  @property({ reflect: true })
  public href?: string; // this is to distinguish a with button class or real button

  @property({ reflect: true })
  public target?: '_blank' | '_parent' | '_self' | '_top';

  @property({type: Boolean, reflect: true})
  public active = false;

  @property({type: String})
  public type: 'button' | 'submit' | 'reset' = 'button';

  @property({type: String})
  public kind: '' | 'primary' | 'secondary' | 'warning' | 'blue' | 'danger';

  @property({type: String})
  public size: '' | 'lg';

  private buildClassName(): string {
    return ``;
  }

  private buttonContent(): TemplateResult {
    return html`
        <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Text">
          <slot></slot>
        </button>`;
  }

  private hrefContent(): TemplateResult {
    return html`<a href="${this.href}" class="btn mt-1 spf-link">
      <slot></slot>
    </a>`;
  }

  constructor() {
    super();
    this.addEventListener('click', this.handleClickCapture, {
      capture: true,
    });
  }

  private handleClickCapture(event: Event): void | boolean {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      return false;
    }
  }

  protected render(): TemplateResult {
    return this.href !== ""
      ? this.hrefContent(): this.buttonContent();
  }
}
