import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import navBarCss from '../scss/navbar.scss';

@customElement('t3-navbar')
export class Navbar extends LitElement {
    @property({type: Object}) data = {};

    constructor() {
        super();
    }

    static get styles() {
        return navBarCss;
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    // correcting the currently available fields for navbar data field.
    protected render(): TemplateResult {
        return html`
            test
        `;
    }
}
