import {LitElement, html, css, TemplateResult} from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('parent-element')
export class Parent extends LitElement {
    @property({ type: Object }) data = { name: '' };

    constructor() {
        super();
        // setTimeout(() => this.data = { name: 'Lille!' }, 1000)
    }

    static get styles() {
        return css`
            * {
              font-color: red;
            }
        `;
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    protected render(): TemplateResult {
        console.log(this.data);
        return html`
            <child-element .data=${this.data}></child-element>
        `
    }
}

// This is a test element
@customElement('child-element')
export class Child extends LitElement {

    @property({ type: Object }) data;

    constructor() {
        super();
    }

    connectedCallback(): void {
        super.connectedCallback();
    }

    render() {
        return html`
            <div>
                Hello ${this.data.name}
            </div>
        `
    }
}
