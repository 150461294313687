import {css, LitElement} from "lit";
import {customElement, property} from "lit/decorators.js";
import tippy, {Instance, Props} from "tippy.js";
//import 'tippy.js/themes/light.css';

@customElement('t3-tooltip')
export class T3ToolTip extends LitElement {
  private display: string;
  private _tippyInstance: Instance<Props>;

  @property({type: String}) for = '';
  @property({type: String}) trigger = '';
  @property({type: Object}) content = {};

  static get styles() {
    return [
      css`
        :host {
          display: none;
        }
      `
    ];
  }

  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  connectedCallback() {
    super.connectedCallback();
    this.display = this.innerHTML;
  }

  disconnectedCallback(){
    super.disconnectedCallback();
    if(this._tippyInstance){
      this._tippyInstance.destroy();
    }
  }

  render() {
    this._tippyInstance = tippy(document.getElementById(this.for), {
      content: this.display,
      allowHTML: true,
      arrow: true,
      animation: 'fade',
      trigger: 'mouseenter', // mouseenter
      theme: 'light',
      maxWidth: '300em',
      placement: 'right',
      interactive : true,
    });
  }
}
